import React, { useEffect, useMemo, useState } from "react";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"; // Import TypeScript types for PDFJS
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf';

PDFJS.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.mjs`;
interface Props {
    pdfToView: Blob | string;
    title: string;
}
function PdfImageView({ pdfToView, title }: Props) {
    const [pdf, setPdf] = useState<PDFDocumentProxy>();
    const [images, setImages] = useState<string[]>([]);
    const [objectUrl, setObjectUrl] = useState<string | null>(null);

    useEffect(() => {
        let url: string | null = null;

        if (pdfToView instanceof Blob) {
            url = URL.createObjectURL(pdfToView);
            setObjectUrl(url);
        } else {
            url = pdfToView;
        }

        const loadPdf = async () => {
            if (!url) return;
            const _PDF_DOC = await PDFJS.getDocument({ url }).promise;
            setPdf(_PDF_DOC);
        };

        loadPdf();

        // Cleanup object URL on component unmount or pdfToView change
        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
                setObjectUrl(null);
            }
        };
    }, [pdfToView])

    const renderPage = async () => {
        if (!pdf) return;

        const imagesList: string[] = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");

        for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale: 1.2 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const render_context = {
                canvasContext: canvas.getContext("2d")!,
                viewport: viewport
            };
            await page.render(render_context).promise;
            const img = canvas.toDataURL("image/png");
            imagesList.push(img);
        }
        setImages(imagesList);
    };

    useEffect(() => {
        if (pdf) {
            renderPage();
        }
    }, [pdf]);

    const imageHtml = useMemo(() => images.map((image, idx) => `
    <div key=${idx} class="border rounded p-0">
      <img
        id="image-generated"
        src=${image}
        alt="pdfImage"
        style="width:100%;height:100%;margin:0;border:none;"
      />
    </div>
    `
    ), [images]);
    return (
        <div className="pdfImageView onlyMobile">
            <iframe src={`data:text/html;charset=utf-8,
        ${imageHtml}
        `} className={"pdfView"} title={title} ></iframe>
        </div>
    );
};

export default PdfImageView;