import { makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import {
  SignUpFormValues,
  User,
  UserFormValues,
  UserChangePassword,
  UserForgotPassword,
  UserLogin,
  StaffUserList,
  UserForm,
} from "../models/user";
import { store } from "./store";
import i18n from "../common/i18n/i18n";
import { DropdownItemProps } from "semantic-ui-react";
import { router } from "../router/Routes";
import { AccessLevel, Application, ClientStatus } from "../models/enums";
import ipAddressAgent from "../api/ipAddressApi";
import { convertKeyValueStringsToObject, resetRegistries, sortingStrings } from "../common/util/functions";
import { IpAddress } from "../common/util/iPAdress";

export default class UserStore {
  user: User | null = null;
  fbAccessToken: string | null = null;
  loadingInitial = false;
  refreshTokenTimeout: any;
  clientId: string | null = null;
  userRegistry = new Map<string | undefined, StaffUserList>();
  editMode: boolean = false;
  roleList: DropdownItemProps[] = [];
  returnMessage: string = "";

  selectedUser: UserFormValues = {
    id: undefined,
    email: undefined,
    username: "",
    application: [],
    accessLevel: undefined,
    firstName: undefined,
    lastName: undefined,
    isInternal: undefined,
    isActive: false,
    password: undefined,
    primaryNumber: undefined,
    cellNumber: undefined,
    referenceNotes: undefined,
    error: undefined,
  };

  forgotPasswordReset = false;

  registrationValues: SignUpFormValues = {
    clientName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    faxNumber: undefined,
    username: "",
    password: "",
    confirmPassword: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    id: undefined,
    error: undefined,
  };

  registrationStep: number = 0;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.userRegistry.clear();
        this.loadUsersList();
      }
    );
  }

  resetUserRegistry = () => {
    this.userRegistry.clear();
    this.staffList = [];
  };

  get isLoggedIn() {
    return !!this.user;
  }

  ipCheck = async () => {
    try {
      const ip = await ipAddressAgent.ipAddressApi.ipAddress();
      return ip;
    } catch (error) {
      console.log(error);

      // Fallback to using cloudflare trace to get ip and loc
      return await this.altIpCheck();
    }
  };

  altIpCheck = async() => {
    try {
      const data = await ipAddressAgent.ipAddressApi.fallbackIpAddress();
      const ipData = convertKeyValueStringsToObject(data);
      const ip: IpAddress = {
        ip: ipData.ip,
        country_code: ipData.loc,
      };

      return ip;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  login = async (credentials: UserLogin) => {
    try {
      //clean out anything that may still be lying around
      resetRegistries();
      localStorage.clear();
      window.sessionStorage.clear();
      this.stopRefreshTokenTimer();
      store.commonStore.setToken(null);
      this.user = null;
      window.localStorage.removeItem("jwt");

      const user = await agent.Account.login(credentials);

      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);

      runInAction(() => {
        this.user = user;
      });

      router.navigate("/");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  initialRedirects = () => {
    if ((this.user?.status ?? 0) <= ClientStatus.Expired) {
      this.user = null;
      toast.error(i18n.t("accountDeclineToast", { ns: "errors" }).toString());
      router.navigate("/");
    } else if (this.user && this.user.forcePasswordReset) {
      router.navigate(`users/changePassword/${this.user.id ?? ""}`);
    } else if (
      this.checkAccessLevel(AccessLevel.Manager) &&
      ((this.user?.status ?? 0) & ClientStatus.Expired) == ClientStatus.Expired
    ) {
      router.navigate("/subscription");
    } else if (
      this.user?.application === Application.None &&
      this.checkStatus(ClientStatus.PreTrial)
    )
      router.navigate("/settings");
    else if (
      this.checkAccessLevel(AccessLevel.Manager) &&
      this.user?.application === Application.None &&
      this.checkStatus(ClientStatus.SignUpInProgress | ClientStatus.Active)
    )
      router.navigate("/trials");
    else if (
      this.checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
      this.checkApplication(Application.RealTimeLaborGuidePro)
    )
      router.navigate("/recent");
    else if (
      this.checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
      this.checkApplication(Application.RealTimeLaborGuide)
    ) {
      router.navigate("/ticket");
    }
  };

  changePassword = async (
    changePassword: UserChangePassword,
    isChange: boolean,
    id: string | undefined,
    token: string | undefined
  ) => {
    try {
      if (isChange) {
        changePassword.id = id;
        await agent.Account.changePassword(changePassword);
      } else {
        changePassword.token = token;
        await agent.Account.ResetPassword(changePassword);
      }
      toast.success(
        i18n.t("changepassword.updatesuccess", { ns: "alerts" }).toString()
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  requestPasswordReset = async (email: UserForgotPassword) => {
    this.forgotPasswordReset = false;
    try {
      await agent.Account.ForgotPassword(email);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      this.forgotPasswordReset = true;
      this.returnMessage =
        "Sent password to this forgetful person " + email.emailAddress;
    }
  };

  logout = async () => {
    resetRegistries();
    this.stopRefreshTokenTimer();

    try {
      router.navigate("/");
      //if (this.user) await agent.Account.logout();
      store.commonStore.setToken(null);
      this.user = null;
      window.localStorage.removeItem("jwt");
      localStorage.clear();
      window.sessionStorage.clear();
    } catch (error) {
      console.log(error);
    }
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      this.startRefreshTokenTimer(user);
    } catch (error) {
      console.log(error);
    }
  };

  getUserNameSuggestion = async (credentials: SignUpFormValues) => {
    try {
      const registrationDto = await agent.Account.getUsernameSuggestion(
        credentials
      );
      runInAction(() => {
        this.registrationStep = 1;
        this.registrationValues.clientName = credentials.clientName;
        this.registrationValues.firstName = credentials.firstName;
        this.registrationValues.lastName = credentials.lastName;
        this.registrationValues.email = credentials.email;
        this.registrationValues.phoneNumber = credentials.phoneNumber;
        this.registrationValues.username = registrationDto.suggestedName;
        this.registrationValues.id = registrationDto.clientId;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  signUp = async (
    credentials: SignUpFormValues,
    token: string,
    ip?: string
  ) => {
    try {
      credentials.ipAddress = ip;
      const user = await agent.Account.signUp(credentials, token);
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
      runInAction(() => {
        this.user = user;
        this.resetRegistration();
      });
      if (user.status && user.status === ClientStatus.PreTrial)
        router.navigate("/settings", { replace: true });
      else router.navigate("/trials", { replace: true });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resetRegistration = () => {
    this.registrationValues = {
      clientName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      faxNumber: undefined,
      username: "",
      password: "",
      confirmPassword: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      id: undefined,
      error: undefined,
    };

    this.registrationStep = 0;
  };

  getUserDetails = async (id: string) => {
    this.loadingInitial = true;
    try {
      let user = await agent.Account.getUser(id);
      runInAction(() => {
        this.loadingInitial = false;
      });
      return user;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  createUser = async (credentials: UserFormValues) => {
    try {
      await agent.Account.addUser(credentials);
      runInAction(() => {
        this.userRegistry.clear();
        this.returnMessage =
          "Staff user " +
          credentials.username +
          " added. The new user's temporary password is: " +
          credentials.password;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  setSelectedUser = (values: UserFormValues) => {
    this.selectedUser = values;
  };

  // setImage = (image: string) => {
  //     if (this.user) this.user.image = image;
  // }

  // setDisplayName = (name: string) => {
  //     if (this.user) this.user.displayName = name;
  // }

  // getFacebookLoginStatus = async () => {
  //   window.FB.getLoginStatus((response) => {
  //     if (response.status === "connected") {
  //       this.fbAccessToken = response.authResponse.accessToken;
  //     }
  //   });
  // };

  // facebookLogin = () => {
  //   this.loadingInitial = true;
  //   const apiLogin = (accessToken: string) => {
  //     agent.Account.fbLogin(accessToken)
  //       .then((user) => {
  //         store.commonStore.setToken(user.token);
  //         this.startRefreshTokenTimer(user);
  //         runInAction(() => {
  //           this.user = user;
  //           this.loadingInitial = false;
  //         });
  //         navigate"/tickets");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         runInAction(() => (this.loadingInitial = false));
  //       });
  //   };
  //   if (this.fbAccessToken) {
  //     apiLogin(this.fbAccessToken);
  //   } else {
  //     window.FB.login(
  //       (response) => {
  //         apiLogin(response.authResponse.accessToken);
  //       },
  //       { scope: "public_profile,email" }
  //     );
  //   }
  // };

  refreshToken = async () => {
    this.stopRefreshTokenTimer();
    try {
      const user = await agent.Account.refreshToken();
      runInAction(() => (this.user = user));
      store.commonStore.setToken(user.token);
      this.startRefreshTokenTimer(user);
    } catch (error) {
      console.log(error);
    }
  };

  private startRefreshTokenTimer(user: User) {
    const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  loadUsersList = async () => {
    this.loadingInitial = true;
    try {
      this.userRegistry.clear();
      const result = await agent.Account.userList();
      runInAction(() => {
        result.forEach((user) => {
          this.setUserRegistry(user);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  get getUserList() {
    return Array.from(this.userRegistry.values()).sort((a, b) => {
      //sortingStrings
      let temp = sortingStrings(
        a.lastName?.toLowerCase() ?? "",
        b.lastName?.toLowerCase() ?? ""
      );
      if (temp === 0) {
        if (temp === 0) {
          temp =
            sortingStrings(
              a.firstName?.toLowerCase() ?? "",
              b.firstName?.toLowerCase() ?? ""
            ) ?? 0;
        }
      }
      return temp ?? 0;
    });
  }

  getActiveUserCount = (): number => {
    return Array.from(this.userRegistry.values()).filter((x) => {
      return x.isActive;
    }).length;
  };

  private setUserRegistry = (userFormValues: StaffUserList) => {
    userFormValues.createdDate = new Date(userFormValues.createdDate!);
    this.userRegistry.set(userFormValues.id, userFormValues);
  };

  private getUserRegistry = (id: string) => {
    return this.userRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  updateUser = async (credentials: UserFormValues) => {
    this.loadingInitial = true;
    try {
      let myNew: UserForm = new UserForm(credentials);
      await agent.Account.updateUser(myNew);
      runInAction(() => {
        this.userRegistry.clear();
        if (myNew.password && myNew.password.length > 0)
          this.returnMessage = `${i18n.t("staff.staffUser", { ns: "users" })} ${
            credentials.username
          } ${i18n.t("staff.temporaryPassword", { ns: "users" })} ${
            credentials.password
          }.`;
        else
          this.returnMessage = `${i18n.t("staff.successfullyUpdated", {
            ns: "users",
          })}.`;
      });
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  toggleUser = async (id: string) => {
    this.loadingInitial = true;
    try {
      await agent.Account.toggleUser(id);
      runInAction(() => {
        if (id) {
          let updatedUser = this.getUserRegistry(id);
          if (updatedUser) updatedUser.isActive = !updatedUser.isActive;
          this.userRegistry.set(id, updatedUser as StaffUserList);
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadRoleList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Account.roleList();
      runInAction(() => {
        this.roleList = result;
      });
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  verifyEmail = async (email: string, token: string) => {
    try {
      var result = await agent.Account.verifyEmail(token, email);
      router.navigate("/", { replace: true });
      toast.success(result);

    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resendEmail = async () => {
    try {
      await agent.Account.resendEmailConfirm();
      router.navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  activateTrial = async (application: Application, isExtension: boolean) => {
    try {
      if (this.user?.id) {
        const user = await agent.Account.activateTrial(
          application,
          isExtension
        );
        runInAction(() => (this.user = user));
        router.navigate("/");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  checkUsername = async (userName: string, id?: string) => {
    try {
      if (userName.length > 0) {
        if (id) await agent.Account.checkUsernamePlus(userName, id);
        else await agent.Account.checkUsername(userName);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  checkEmail = async (email: string, id?: string) => {
    try {
      if (email && email.length > 0) {
        if (id) await agent.Account.checkEmailPlus(email, id);
        else await agent.Account.checkEmail(email);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resetReturnMessage = () => {
    try {
      runInAction(() => {
        this.returnMessage = "";
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  staffList: DropdownItemProps[] = [];
  loadStaff = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Account.listStaffUsers();
      runInAction(() => {
        this.staffList = result;
      });
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  checkStatus = (clientStatus: ClientStatus): boolean => {
    let returnValue: boolean = false;

    if (this.user?.status) returnValue = this.user.status >= clientStatus;
    return returnValue;
  };

  checkAccessLevel = (accessLevel: AccessLevel) => {
    return this.user?.accessLevel && this.user.accessLevel >= accessLevel;
  };

  checkApplication = (application: Application) => {
    return this.user?.application && this.user.application >= application;
  };

  emulateUser = async (userName?: string) => {
    try {
      const user = await agent.SystemAdminTools.emulateUser(userName ?? "");
      runInAction(() => {
        const token = window.localStorage.getItem("jwt") as string;
        localStorage.clear();
        window.sessionStorage.clear();
        this.stopRefreshTokenTimer();
        store.commonStore.setToken(null);
        this.user = null;
        window.localStorage.setItem("adminjwt", token);
        window.localStorage.removeItem("jwt");

        store.commonStore.setToken(user.token);
        this.startRefreshTokenTimer(user);
        this.user = user;
      });
    } catch (error) {
      console.log(error);
    }
  };

  restoreUser = async (userName?: string) => {
    try {
      runInAction(() => {
        const token = window.localStorage.getItem("adminjwt");
        localStorage.clear();
        window.sessionStorage.clear();
        this.stopRefreshTokenTimer();

        store.commonStore.setToken(token);
        this.refreshToken();
      });
    } catch (error) {
      console.log(error);
    }
  };
}
