import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {
  Button,
  Container,
  Message,
  Image,
  Card,
  Divider,
  List,
  ListItem,
  Icon,
  Grid,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Application } from "../../app/models/enums";
import { format } from "date-fns";
import { useStore } from "../../app/stores/store";
import PageHeader from "../../app/layout/PageHeader";
import { event } from "../../app/common/util/gtag";

interface Props {
  isExtension: boolean;
  application: Application;
  expirationDate: Date;
  conversionId?: string;
}

export default observer(function TrialsDetails({
  application,
  isExtension,
  expirationDate,
  conversionId,
}: Props) {
  const { t } = useTranslation(["common", "subscription"]);
  const { userStore, subscriptionStore } = useStore();
  const { activateTrial, initialRedirects, getUser, user } = userStore;
  const { createTrialExtension } = subscriptionStore;

  const [disabledButton, setDisableButton] = useState<boolean>(false);

  const image =
    application === Application.RealTimeLaborGuide
      ? "real_time_labor_guide_logo.png"
      : "real_time_pro_logo.png";
  const buttonClassName =
    application === Application.RealTimeLaborGuide
      ? "RTLG_button"
      : "RTP_button";
  const header =
    application === Application.RealTimeLaborGuide
      ? `${t("service_type.realTimeGuide", { ns: "common" })} ${t(
          "trial.label",
          { ns: "subscription" }
        )}`
      : `${t("service_type.realTimePro", { ns: "common" })} ${t("trial.label", {
          ns: "subscription",
        })}`;

  const message =
    application === Application.RealTimeLaborGuide
      ? t("trial.laborGuideMessage", { ns: "subscription" })
      : t("trial.proMessage", { ns: "subscription" });

  return (
    <Card className="full-width">
      <Card.Content>
        <Grid stackable>
          <Grid.Row columns={2} verticalAlign="middle">
            <Grid.Column>
              <Image size="medium" src={`/assets/${image}`}></Image>
            </Grid.Column>
            <Grid.Column>
              <Button
                className={`vertical-align-middle ${buttonClassName}`}
                floated="right"
                disabled={disabledButton}
                loading={disabledButton}
                onClick={() => {
                  setDisableButton(true);
                  if (isExtension) {
                    createTrialExtension(user?.clientId ?? "", false, 7)
                      .then(() => {
                        getUser()
                          .then(() => {
                            initialRedirects();
                          })
                          .catch()
                          .finally(() => {
                            setDisableButton(false);
                          });
                      })
                      .catch()
                      .finally(() => {
                        setDisableButton(false);
                      });
                  } else {
                    activateTrial(application, isExtension)
                      .then(() => {
                        event(
                          "conversion",
                          "AW-715275038/FVz9CN7Fl94ZEJ72iNUC"
                        );
                        getUser()
                          .then(() => {
                            initialRedirects();
                          })
                          .catch()
                          .finally(() => {
                            setDisableButton(false);
                          });
                      })
                      .catch()
                      .finally(() => {
                        setDisableButton(false);
                      });
                  }
                }}
                size="large"
              >
                {isExtension
                  ? t("trial.extend", { ns: "subscription" })
                  : t("trial.activate", { ns: "subscription" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <PageHeader
          type={"h3"}
          header={header}
          divider={false}
          getAlerts={true}
          addTitle={false}
        />
        <Message info content={message} />
        <Container>
          {application === Application.RealTimeLaborGuidePro && (
            <>
              <p>{t("trial.proDetail1", { ns: "subscription" })}</p>
              <p>{t("trial.proDetail2", { ns: "subscription" })}</p>
              <p>{t("trial.proDetail3", { ns: "subscription" })}</p>
              <p>{t("trial.proDetail4", { ns: "subscription" })}</p>
              <br />
              <br />
              <strong>
                {t("features.highlights", { ns: "subscription" })}
              </strong>
              <List>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.laborguide", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.packageJobs", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.misccharges", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.unlimitedtickets", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.quickvindecoding", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.staffmanagement", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.repairorder", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.customerhistory", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.tickethistory", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.shopreports", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.techtracking", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.partsmarkup", { ns: "subscription" })}
                </ListItem>
              </List>
            </>
          )}

          {application === Application.RealTimeLaborGuide && (
            <>
              <p>{t("trial.laborGuideDetail1", { ns: "subscription" })}</p>
              <p>{t("trial.laborGuideDetail2", { ns: "subscription" })}</p>
              <p>{t("trial.laborGuideDetail3", { ns: "subscription" })}</p>
              <br />
              <br />
              <strong>
                {t("features.highlights", { ns: "subscription" })}
              </strong>
              <List>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.laborguide", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.packageJobs", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.misccharges", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.unlimitedtickets", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.quickvindecoding", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.staffmanagement", { ns: "subscription" })}
                </ListItem>
                <ListItem>
                  <Icon name="check square outline" />
                  {t("features.repairorder", { ns: "subscription" })}
                </ListItem>
              </List>
            </>
          )}
        </Container>
      </Card.Content>
      <Card.Content extra>
        <span className="card-span-right">
          {`${t("trial.activatetimeperiod", { ns: "subscription" })}: ${format(
            expirationDate,
            "PP"
          )}`}
        </span>
      </Card.Content>
    </Card>
  );
});
