import { BlobProvider } from "@react-pdf/renderer";
import { useRef } from "react";
import { pdfjs } from "react-pdf";

//I added this imports to add suport to textLayer and anotations
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import LoadingComponent from "../../layout/LoadingComponent";
import MobilePdfImageView from "../../../features/tickets/Pdf/MobilePdfImageView";

//\node_modules\pdfjs-dist\build
//this due a Worker not found error
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`,
  import.meta.url
).toString();

type Props = {
  document: any;
  title: string;
  filename?: string;
  isLoading?: boolean;
  className?: React.CSSProperties | string;
};

const PdfObjectViewer = (props: Props) => {
  const parentRef = useRef<HTMLDivElement>(null);
  return (
    <div id="pdf" className={props.className + ""}>
      <BlobProvider document={props.document}>
        {({ blob, url, loading, error }) => (
          //     loading ? (
          //       <LoadingComponent />
          //     ) : (
          //       <Document file={url} >
          //         <Page
          //           //loading={loading ? <LoadingComponent /> : null}
          //           pageNumber={1}
          //           error={"Error"}
          //           width={parentRef.current?.clientWidth}
          //         />
          //       </Document>
          //    )
          <>
            <MobilePdfImageView pdfToView={blob!} title={props.title} />
            <embed
              src={url ?? ""}
              width={"100%"}
              className={"onlyDesktop"}
              height={window.innerHeight}
              title={props.filename}
            ></embed>
          </>
        )}
      </BlobProvider>
    </div>
  );
};

export default PdfObjectViewer;
